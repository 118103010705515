<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              MeStream
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome back!
          </p>
          <p class="mb-2">
            Please log into your account
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-progress-linear
              v-if="submitted"
              indeterminate
          />
          <v-form ref="loginForm" :disabled="submitted">
            <v-text-field
              v-model="username"
              outlined
              label="Username"
              placeholder="JohnDoe"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              v-on:click="login(username, password)"
            >
              Login
            </v-btn>
          </v-form>
          <v-progress-linear
              v-if="submitted"
              indeterminate
          />
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New here?
          </span>
          <router-link :to="{name:'signup'}">
            Create an account
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2" v-if="socialLink.length > 0">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center" v-if="socialLink.length > 0">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>

    <!-- dialogs -->
    <message-dialog
        v-model="errorDialog"
        v-on:OK="errorDialog = false"
        title="Uh oh! Error!"
    >
      <p>
        Our apologies. There was an error processing your request.
      </p>
      <p>
        Please try again later, or contact us at <a href="mailto:info@mestream.io">info@mestream.io</a>
      </p>
    </message-dialog>
    <nothing-dialog v-model="nothingDialog" v-on:OK="nothingDialog = false" />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {Action} from '@/store/session/types'
import NothingDialog from "@/components/NothingDialog";
import MessageDialog from "@/components/MessageDialog";

export default {
  components: {NothingDialog, MessageDialog},
  data: () => ({
    submitted: false,
    nothingDialog: false,
    errorDialog: false,
  }),
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const socialLink = []

    const usernameRules = ref(
        [
          v => !!v || 'Required',
          v => (v && v.length >= 2) || 'Minimum 2 characters',
          v => (v && v.length <= 30) || 'Max 30 characters',
        ]
    )

    const passwordRules = ref(
        [
            v => !!v || 'Required',
            v => (v && v.length >= 7) || 'Minimum 7 characters',
        ]
    )

    return {
      isPasswordVisible,
      username,
      password,
      socialLink,
      usernameRules,
      passwordRules,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    login: function(username, password) {
      // TODO: remember username somewhere in vuex store...?
      // - or local storage...?
      this.submitted = true
      this.$store.dispatch(
          `${[Action.Login]}`,
          {username: username, password: password}
      ).then(
          () => {
            this.password = ref("")
            this.handleLogin()
            this.submitted = false
          }
      ).catch(
          () => {
            this.errorDialog = true
            this.submitted = false
          }
      )
    },
    handleLogin: function() {
      this.$router.push(this.$route.query.redirect || '/home')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
